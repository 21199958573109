import { generateHreflangs } from 'global-config';
import PropTypes from 'prop-types';
import {
  AlternateLanguages,
  Canonical,
  CopySections,
  MetaData,
  ContentAutorules,
  Robots,
} from './components';

const SeoView = ({ currentPath }) => (
  <>
    <MetaData />
    <Canonical />
    { generateHreflangs && <AlternateLanguages currentPath={currentPath} /> }
    <CopySections />
    <ContentAutorules />
    <Robots />
  </>
);

SeoView.propTypes = {
  currentPath: PropTypes.string.isRequired,
};

export default SeoView;
