import fetchTemplates from './fetch_templates';

const SPORT_URL = ({ id }) => `/bv_api/seo/sport/content_autorules/${id}`;

const typeToUrl = {
  pre_event: SPORT_URL,
  in_play: SPORT_URL,
  meeting: SPORT_URL,
};

export default fetchTemplates(typeToUrl);
