import PropTypes from 'prop-types';
import ContentAutorulesView from './content_autorules_view';
import ContentAutorulesTemplate from './components/content_autorules_template';

const ContentAutorulesContainer = ({
  content,
  copySections,
  fetching,
  prerenderReady,
}) => {
  if (fetching || copySections?.length) return null;
  if (content) {
    return (
      <ContentAutorulesView
        content={content}
        prerenderReady={prerenderReady}
      />
    );
  }
  return <ContentAutorulesTemplate />;
};

ContentAutorulesContainer.propTypes = {
  content: PropTypes.string,
  copySections: PropTypes.arrayOf(Object),
  fetching: PropTypes.bool,
  prerenderReady: PropTypes.bool,
};

ContentAutorulesContainer.defaultProps = {
  content: null,
  copySections: [],
  fetching: false,
  prerenderReady: false,
};

export default ContentAutorulesContainer;
