import { useState } from 'react';
import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import striptags from 'striptags';
import { Icon } from 'bv-components';
import { v as bvVar } from 'bv';

const collapsedTextLength = 200;

const ExpandableParagraph = ({ text }) => {
  const [expanded, setExpanded] = useState(bvVar('prerender'));

  return (
    <div className="site-copy-section__content" onClick={() => { setExpanded(!expanded); }}>
      <span
        /* eslint risxss/catch-potential-xss-react:off */
        dangerouslySetInnerHTML={{
          __html: expanded ? sanitize(text,
            { ADD_TAGS: ['iframe'], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] })
            : `${striptags(text).slice(0, collapsedTextLength)}...`,
        }}
      />
      <Icon className="expand-button" id={`arrow-solid-circle-${expanded ? 'up' : 'down'}`} />
    </div>
  );
};

ExpandableParagraph.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ExpandableParagraph;
