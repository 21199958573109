import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentContentAutorulesTemplate } from 'Seo/selectors';
import ContentAutorulesView from 'Seo/components/content_autorules/content_autorules_view';
import ContentAutorulesTemplateContainer from './content_autorules_template_container';

const ContentAutorulesTemplateRedux = ({ data, ...rest }) => (data
  ? <ContentAutorulesView />
  : <ContentAutorulesTemplateContainer data={data} {...rest} />);

ContentAutorulesTemplateRedux.propTypes = {
  data: PropTypes.instanceOf(Object),
};

ContentAutorulesTemplateRedux.defaultProps = {
  data: null,
};

export default connect(getCurrentContentAutorulesTemplate)(ContentAutorulesTemplateRedux);
