export default (templates, data) => {
  try {
    return Object.entries(templates).reduce(
      (accum, [element, { attributes, parsed_template: template }]) => ({
        ...accum,
        [element]: template.replace(/\[(.*?)\]/g, (match, pos) => data[attributes[pos]] || ''),
      }),
      {},
    );
  } catch (error) { return {}; }
};
