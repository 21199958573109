import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentMetaInfo } from 'Seo/selectors';
import MetaDataView from 'Seo/components/meta_data/meta_data_view';
import MetaInfoContainer from './meta_info_container';

const MetaInfoRedux = ({ data, metaDataProps, ...rest }) => (data
  ? <MetaInfoContainer data={data} {...rest} />
  : <MetaDataView {...metaDataProps} />);

MetaInfoRedux.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  metaDataProps: PropTypes.instanceOf(Object),
};

MetaInfoRedux.defaultProps = {
  metaDataProps: {},
};

export default connect(getCurrentMetaInfo)(MetaInfoRedux);
