import fetchTemplates from './fetch_templates';

const SPORT_URL = ({ id }) => `/bv_api/seo/sport/meta_info/${id}`;
const CASINO_URL = () => '/bv_api/seo/casino/meta_info';
const OFFER_URL = () => '/bv_api/seo/offer/meta_info';

const typeToUrl = {
  pre_event: SPORT_URL,
  in_play: SPORT_URL,
  meeting: SPORT_URL,
  game: CASINO_URL,
  casino_category: CASINO_URL,
  live_casino_category: CASINO_URL,
  offer: OFFER_URL,
};

export default fetchTemplates(typeToUrl);
