import { connect } from 'react-redux';
import { getContentAutorules, getCopySections } from 'Seo/selectors';
import ContentAutorulesContainer from './content_autorules_container';

const mapStateToProps = (state) => ({
  contentAutorules: getContentAutorules(state),
  copySections: getCopySections(state),
});

export default connect(mapStateToProps)(ContentAutorulesContainer);
