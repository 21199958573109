import PropTypes from 'prop-types';
import RobotsView from './robots_view';

const RobotsContainer = ({
  fetching, noindex, nofollow, unavailableAfter,
}) => {
  if (fetching) return null;

  const content = [
    noindex && 'noindex',
    nofollow && 'nofollow',
    unavailableAfter ? `unavailable_after: ${unavailableAfter}` : '',
  ].filter(Boolean).toString();

  return <RobotsView content={content} />;
};

RobotsContainer.propTypes = {
  fetching: PropTypes.bool,
  noindex: PropTypes.bool,
  nofollow: PropTypes.bool,
  unavailableAfter: PropTypes.string,
};

RobotsContainer.defaultProps = {
  fetching: null,
  noindex: null,
  nofollow: null,
  unavailableAfter: null,
};

export default RobotsContainer;
