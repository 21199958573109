import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { v as bvVar } from 'bv';
import { createPortal } from 'react-dom';
import { useFeature } from 'bv-hooks';

const descriptionElement = document.querySelector('meta[name="description"]');

const HeadPortal = ({ children }) => createPortal(children, document.head);

const MetaDataView = ({
  title, description, prerenderReady, hasCustomSchema, schemaJson,
}) => {
  const [gameInfoEnabled] = useFeature('casino_game_info_page');

  useEffect(
    () => {
      if (title) document.title = title;
      if (description) descriptionElement.content = description;
      if (!window.prerenderReady) {
        window.prerenderReady = prerenderReady;
      }
    },
    [title, description, prerenderReady],
  );

  return (
    <HeadPortal>
      {gameInfoEnabled && hasCustomSchema && schemaJson && <script type="application/ld+json">{schemaJson}</script>}
    </HeadPortal>
  );
};

MetaDataView.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  prerenderReady: PropTypes.bool,
  hasCustomSchema: PropTypes.bool,
  schemaJson: PropTypes.string,
};

MetaDataView.defaultProps = {
  title: bvVar('siteName'),
  description: bvVar('siteName'),
  prerenderReady: false,
  hasCustomSchema: false,
  schemaJson: null,
};

export default MetaDataView;
