import { connect } from 'react-redux';
import { compose } from 'underscore';
import withCurrentPath from 'Seo/hocs/with_current_path';

import seo, {
  setMetaInfo,
  fetchSeoEntity,
  setPrerenderReady,
} from './duck';
import SeoContainer from './seo_container';

const { addReducers, addActionDispatchers } = window.reduxState;

addReducers({
  seo,
});

addActionDispatchers('seo', { setMetaInfo, setPrerenderReady });

const mapDispatchToProps = (dispatch) => ({
  fetchSeoEntity: compose(dispatch, fetchSeoEntity),
});

export default withCurrentPath()(connect(null, mapDispatchToProps)(SeoContainer));
