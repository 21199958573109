import { useEffect } from 'react';
import PropTypes from 'prop-types';
import SeoView from './seo_view';

const SeoContainer = ({
  fetchSeoEntity,
  currentPath,
}) => {
  useEffect(() => {
    fetchSeoEntity(currentPath);
  }, [currentPath]);

  return <SeoView currentPath={currentPath} />;
};

SeoContainer.propTypes = {
  fetchSeoEntity: PropTypes.func.isRequired,
  currentPath: PropTypes.string.isRequired,
};

export default SeoContainer;
