import { createSelector } from 'reselect';
import { property, pick, compose } from 'underscore';
import { routing as routingSelectors } from 'redux-selectors';
import { v as bvVar } from 'bv';
import { withoutTrailingSlash } from 'bv-helpers';

const seoState = property('seo');

export const getMetaData = (state) => pick(seoState(state), 'title', 'description', 'fetching', 'isDefault', 'prerenderReady', 'hasCustomSchema', 'schemaJson');
export const getContentAutorules = (state) => pick(seoState(state), 'description', 'fetching', 'prerenderReady');
export const getHeading = compose(property('heading'), seoState);
export const getHasToc = compose(property('hasToc'), seoState);
const getRobotsData = (state) => pick(seoState(state), 'nofollow', 'noindex', 'unavailableAfter');
export const getCopySections = compose(property('copySections'), seoState);
export const getCustomPath = compose(property('customPath'), seoState);
export const getCustomSchema = (state) => {
  const { hasCustomSchema, schemaJson } = seoState(state);

  const parsedSchemaJson = (() => {
    try {
      return typeof schemaJson === 'string' ? JSON.parse(schemaJson) : {};
    } catch (error) {
      return {};
    }
  })();

  return {
    hasCustomSchema,
    schemaJson: parsedSchemaJson,
  };
};

export const getCustomPathWithoutLocale = createSelector(
  [getCustomPath],
  (customPath) => customPath && withoutTrailingSlash(customPath.replace(/^\/[a-z]{2}[-_][a-z]{2}/i, '')),
);

const getMetaInfo = compose(property('metaInfo'), seoState);

const { getCurrentPath } = routingSelectors;

export const getCurrentMetaInfo = (state) => {
  const metaInfo = getMetaInfo(state);

  if (metaInfo && getCurrentPath(state) === metaInfo.path) {
    return metaInfo;
  }

  return {};
};

export const getRobotsContent = (state) => {
  const robotsData = getRobotsData(state);

  if (robotsData.noindex == null) {
    const metaInfo = getMetaInfo(state);

    if (metaInfo?.type === 'meeting' && metaInfo?.id && bvVar('noindexSportConfig')[metaInfo?.id]) {
      robotsData.noindex = bvVar('noindexSportConfig')[metaInfo?.id].meeting;
    } else if (metaInfo?.type === 'pre_event' && metaInfo?.id && bvVar('noindexSportConfig')[metaInfo?.id]) {
      robotsData.noindex = bvVar('noindexSportConfig')[metaInfo?.id].event;
    }
  }

  return robotsData;
};

export const getCurrentContentAutorulesTemplate = (state) => getMetaInfo(state) || {};
