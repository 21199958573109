import PropTypes from 'prop-types';
import Head from 'Seo/components/head';

const RobotsView = ({ content }) => (
  <Head>
    <meta name="robots" content={content} />
  </Head>
);

RobotsView.propTypes = {
  content: PropTypes.string.isRequired,
};

export default RobotsView;
