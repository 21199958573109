import { getJSON } from 'bv-fetch';
import { locale } from 'bv-i18n';

export default (typeToUrl) => {
  const promises = {};

  return ({ type, id }) => {
    if (!typeToUrl[type]) return Promise.resolve({});

    const isSport = ['pre_event', 'in_play', 'meeting'].includes(type);

    const key = isSport ? `${type}${id}` : type;
    const url = typeToUrl[type]({ id });

    promises[key] = promises[key] || getJSON(url, {
      locale: locale(),
      type,
    });

    return promises[key];
  };
};
