import { connect } from 'react-redux';
import { routing as routingSelectors } from 'redux-selectors';
import { getCustomPath, getCustomPathWithoutLocale } from 'Seo/selectors';

const { getCurrentPath, getCurrentPathWithoutLocale } = routingSelectors;

const mapStateToProps = (state) => ({
  currentPath: getCurrentPath(state),
  currentPathWithoutLocale: getCurrentPathWithoutLocale(state),
  customPath: getCustomPath(state),
  customPathWithoutLocale: getCustomPathWithoutLocale(state),
});

export default () => (WrappedComponent) => (
  connect(mapStateToProps)(WrappedComponent)
);
