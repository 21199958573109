import { forwardRef } from 'react';
import { Carousel, Link } from 'bv-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Toc = forwardRef(({ fixedNav, tocTitles, onTocLinkClick }, ref) => {
  const tocClass = classNames('site-copy-links', {
    'is-scrolling': fixedNav,
  });

  const tocLinks = tocTitles.map((title) => (
    <Link
      to={`#${title.replace(/\s+/g, '-')}`}
      className="site-copy-links__link"
      onClick={(e) => onTocLinkClick(e, title)}
      key={title}
    >
      {title}
    </Link>
  ));

  return (
    <Carousel
      scrollStep={284}
      sliderClassName={tocClass}
      ref={ref}
    >
      {tocLinks}
    </Carousel>
  );
});

Toc.propTypes = {
  fixedNav: PropTypes.bool.isRequired,
  tocTitles: PropTypes.instanceOf(Array).isRequired,
  onTocLinkClick: PropTypes.func.isRequired,
};

export default Toc;
