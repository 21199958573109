import { useFetch } from 'bv-hooks';
import PropTypes from 'prop-types';
import fetchContentAutorulesTemplate from 'Seo/api/fetch_content_autorules_template';
import ContentAutorulesView from 'Seo/components/content_autorules/content_autorules_view';
import buildContentAutorules from '../../../meta_data/components/meta_info/helpers/build_meta_info';

const ContentAutorulesContainer = ({ type, id, data }) => {
  const [templates, fetching] = useFetch(
    () => fetchContentAutorulesTemplate({ type, id }),
    [id],
  );

  if (fetching) return null;

  // if we have content autorules loaded, we assume all data for rendering is there
  // so we can safely tell prerender.io that we are done
  return <ContentAutorulesView {...buildContentAutorules(templates, data)} prerenderReady />;
};

ContentAutorulesContainer.propTypes = {
  type: PropTypes.oneOf('pre_event', 'in_play', 'meeting'),
  id: PropTypes.number,
  data: PropTypes.instanceOf(Object).isRequired,
};

ContentAutorulesContainer.defaultProps = {
  type: '',
  id: null,
};

export default ContentAutorulesContainer;
