import { useFetch } from 'bv-hooks';
import PropTypes from 'prop-types';
import fetchAlternateLanguages from 'Seo/api/fetch_alternate_languages';
import AlternateLanguagesView from './alternate_languages_view';

const AlternateLanguagesContainer = ({ currentPathWithoutLocale, customPathWithoutLocale }) => {
  const [data, loading] = useFetch(() => (
    fetchAlternateLanguages(currentPathWithoutLocale)), [currentPathWithoutLocale],
  );

  if (loading) return null;

  return (
    <AlternateLanguagesView
      languages={data.internalLinks || data}
      externalLinks={data.externalLinks || []}
      currentPathWithoutLocale={currentPathWithoutLocale}
      customPathWithoutLocale={customPathWithoutLocale}
    />
  );
};

AlternateLanguagesContainer.propTypes = {
  currentPathWithoutLocale: PropTypes.string.isRequired,
  customPathWithoutLocale: PropTypes.string,
};

AlternateLanguagesContainer.defaultProps = {
  customPathWithoutLocale: null,
};

export default AlternateLanguagesContainer;
