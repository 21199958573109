import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import CopySection from './components/copy_section';
import FaqSection from './components/faq_section';
import Toc from './components/toc';

const CopySectionsView = ({
  copySections, heading, tocVisible, contentElRef, fixedNav,
  navRef, isMainSection, isFaqSectionVisible, schemaJson,
  tocTitles, onTocLinkClick, copySectionRefs,
}) => {
  if (
    !(copySections && copySections.length)
    && (!heading || isMainSection) && !isFaqSectionVisible
  ) return null;

  return (
    <div className="site-copy-wrapper site-copy" ref={contentElRef}>
      {tocVisible && (
        <Toc
          ref={navRef}
          fixedNav={fixedNav}
          tocTitles={tocTitles}
          onTocLinkClick={onTocLinkClick}
        />
      )}

      {heading && !isMainSection && (
        <h1 className="site-copy-heading bvs-h3">
          {heading}
        </h1>
      )}

      {copySections && (
        <div className="site-copy-section-wrapper">
          {copySections && copySections.map(({ title, content, toc_title: tocTitle }) => (
            <CopySection
              key={title}
              title={title}
              content={content}
              ref={copySectionRefs[tocTitle]}
            />
          ))}
        </div>
      )}
      {isFaqSectionVisible && (Array.isArray(schemaJson.mainEntity) || typeof schemaJson.mainEntity === 'object') && (
        <FaqSection
          title={t('javascript.seo.faq_section_title')}
          content={schemaJson.mainEntity}
          ref={copySectionRefs[t('javascript.seo.faq_toc_title')]}
        />
      )}
    </div>
  );
};

CopySectionsView.propTypes = {
  copySections: PropTypes.instanceOf(Array).isRequired,
  heading: PropTypes.string,
  tocVisible: PropTypes.bool.isRequired,
  contentElRef: PropTypes.instanceOf(Object).isRequired,
  fixedNav: PropTypes.bool.isRequired,
  navRef: PropTypes.instanceOf(Object).isRequired,
  isMainSection: PropTypes.bool.isRequired,
  isFaqSectionVisible: PropTypes.bool.isRequired,
  schemaJson: PropTypes.instanceOf(Object).isRequired,
  tocTitles: PropTypes.instanceOf(Array).isRequired,
  onTocLinkClick: PropTypes.func.isRequired,
  copySectionRefs: PropTypes.instanceOf(Object).isRequired,
};

CopySectionsView.defaultProps = {
  heading: null,
};

export default CopySectionsView;
