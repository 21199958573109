import { connect } from 'react-redux';
import withCurrentPath from 'Seo/hocs/with_current_path';
import {
  getHeading, getCopySections, getCustomSchema, getHasToc,
} from 'Seo/selectors';
import CopySectionsContainer from './copy_sections_container';

const mapStateToProps = (state) => ({
  heading: getHeading(state),
  hasToc: getHasToc(state),
  copySections: getCopySections(state),
  customSchema: getCustomSchema(state),
});

export default withCurrentPath()(connect(mapStateToProps)(CopySectionsContainer));
