import {
  useEffect, useState, useRef, useMemo, createRef,
} from 'react';
import { throttle } from 'underscore';
import { v as bvVar } from 'bv';
import { homeProduct as homeProductConfig } from 'global-config';
import { calculateScrollPosition } from 'Seo/helpers/scroll_helper';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { useFeature } from 'bv-hooks';
import ScrollingContext from './scrolling_context';
import View from './copy_sections_view';

/*
Considers main sections to be /sports/{id} /offer/{slug} and any casino lobby
(taken from bvVars, ex /casino, /live-casino, /slots).
These pages have a h1 element at the beginning of the content so should not be
considered here.
*/

const MAIN_SECTION_REGEX = new RegExp(`^/[a-z]{2}-[a-z]{2}(?:${bvVar('casinoLobbies').map(({ slug }) => `/${slug}|`).join('')}/offer/\\w{1,}|/sports/\\w{1,}|/pages/)`);
const [homeProduct] = homeProductConfig.split('::');

const CopySectionsContainer = ({
  currentPath, customSchema, copySections, hasToc, ...props
}) => {
  const [gameInfoEnabled] = useFeature('casino_game_info_page');
  const [fixedNav, setFixedNav] = useState(false);
  const navRef = useRef(null);
  const contentElRef = useRef(null);
  const { hasCustomSchema, schemaJson } = customSchema;
  const isFaqSectionVisible = gameInfoEnabled && hasCustomSchema && schemaJson['@type'] === 'FAQPage';
  const tocTitles = copySections.map((s) => s.toc_title).filter(Boolean);
  const faqTitle = isFaqSectionVisible ? [t('javascript.seo.faq_toc_title')] : [];
  const tocVisible = gameInfoEnabled && hasToc;

  const isMainSection = (
    (homeProduct === 'casino' && currentPath === '/')
    || MAIN_SECTION_REGEX.test(currentPath)
  );

  const copySectionRefs = [...tocTitles, ...faqTitle].reduce((acc, title) => {
    acc[title] = { section: createRef(), title: createRef() };
    return acc;
  }, {});

  const onTocLinkClick = (e, title) => {
    e.preventDefault();
    const element = copySectionRefs[title];
    const newPosition = calculateScrollPosition(element, navRef);
    window.scrollTo({
      top: newPosition,
      behavior: 'smooth',
    });
  };

  const contextValue = useMemo(() => (
    { contentElRef, fixedNav, navRef }
  ), [contentElRef, fixedNav, navRef]);

  useEffect(() => {
    const calculateFixedNav = throttle(() => {
      if (!navRef.current) return;
      setFixedNav(
        window.scrollY >= (navRef.current.offsetTop - (
          navRef.current.clientHeight + (document.querySelector('.site-header')?.clientHeight || 0)
        )),
      );
    }, 150);

    window.addEventListener('scroll', calculateFixedNav);

    return () => { window.removeEventListener('scroll', calculateFixedNav); };
  }, []);

  return (
    <ScrollingContext.Provider value={contextValue}>
      <View
        contentElRef={contentElRef}
        navRef={navRef}
        fixedNav={fixedNav}
        isMainSection={isMainSection}
        isFaqSectionVisible={isFaqSectionVisible}
        schemaJson={schemaJson}
        onTocLinkClick={onTocLinkClick}
        copySections={copySections}
        tocTitles={[...tocTitles, ...faqTitle]}
        copySectionRefs={copySectionRefs}
        tocVisible={tocVisible}
        {...props}
      />
    </ScrollingContext.Provider>
  );
};

CopySectionsContainer.propTypes = {
  currentPath: PropTypes.string.isRequired,
  customSchema: PropTypes.instanceOf(Object).isRequired,
  copySections: PropTypes.instanceOf(Array).isRequired,
  hasToc: PropTypes.bool.isRequired,
};

export default CopySectionsContainer;
