import PropTypes from 'prop-types';
import CanonicalView from './canonical_view';

const CanonicalContainer = ({
  currentPath,
  customPath,
  fetching,
}) => {
  if (fetching) return null;
  // Check homepage's canonical tag URL doesn't have a trailing slash with
  // currentPath.replace(/^\/$/, '')
  return <CanonicalView currentPath={customPath || currentPath.replace(/\/market_group\/\d+|\/$/g, '')} />;
};

CanonicalContainer.propTypes = {
  currentPath: PropTypes.string.isRequired,
  customPath: PropTypes.string,
  fetching: PropTypes.bool,
};

CanonicalContainer.defaultProps = {
  customPath: null,
  fetching: null,
};

export default CanonicalContainer;
