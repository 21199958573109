import PropTypes from 'prop-types';
import MetaDataView from './meta_data_view';
import MetaInfo from './components/meta_info';

const MetaDataContainer = (props) => {
  const {
    title,
    description,
    fetching,
    isDefault,
    prerenderReady,
    hasCustomSchema,
    schemaJson,
  } = props;

  if (fetching) return null;
  if (!isDefault) {
    return (
      <MetaDataView
        title={title}
        description={description}
        prerenderReady={prerenderReady}
        hasCustomSchema={hasCustomSchema}
        schemaJson={schemaJson}
      />
    );
  }
  return <MetaInfo metaDataProps={props} />;
};

MetaDataContainer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  fetching: PropTypes.bool.isRequired,
  isDefault: PropTypes.bool,
  prerenderReady: PropTypes.bool.isRequired,
  hasCustomSchema: PropTypes.bool,
  schemaJson: PropTypes.string,
};

MetaDataContainer.defaultProps = {
  title: null,
  description: null,
  isDefault: null,
  hasCustomSchema: false,
  schemaJson: null,
};

export default MetaDataContainer;
