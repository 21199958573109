import PropTypes from 'prop-types';
import Head from 'Seo/components/head';

const { location } = window;

const CanonicalView = ({ currentPath }) => (
  <Head>
    <link
      rel="canonical"
      href={`${location.protocol}//${location.host}${currentPath}`}
    />
  </Head>
);

CanonicalView.propTypes = {
  currentPath: PropTypes.string.isRequired,
};

export default CanonicalView;
