import { useState } from 'react';
import { Icon } from 'bv-components';
import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';

const FaqParagraph = ({ title, content }) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <div className="site-faq-section__item">
      <div className="bvs-link site-faq-section__item-header" onClick={() => setExpanded(!expanded)}>
        {sanitize(title)}
        <Icon className="site-faq-section__item-button" id={`arrow-solid-circle-${expanded ? 'up' : 'down'}`} />
      </div>
      <div className={`bvs-card site-faq-section__item-content is-${expanded ? 'expanded' : 'collapsed'}`}>{sanitize(content)}</div>
    </div>
  );
};

FaqParagraph.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default FaqParagraph;
