import { useEffect } from 'react';
import { sanitize } from 'dompurify';
import PropTypes from 'prop-types';
import { isDesktop } from 'bv';
import ExpandableParagraph from '../copy_sections/components/expandable_paragraph';

const collapsedTextLength = 200;

const ContentAutorulesView = ({ content, prerenderReady }) => {
  useEffect(
    () => {
      window.prerenderReady = prerenderReady;
    },
    [content, prerenderReady],
  );

  if (!content) return null;

  return (
    <section className="site-seo-content-autorules">
      <div className="site-seo-content-autorules__content">
        {(isDesktop() || (content && content.length <= collapsedTextLength)) ? (
          <span dangerouslySetInnerHTML={{ __html: sanitize(content) }} />
        ) : (
          <ExpandableParagraph text={content} />
        )}
      </div>
    </section>
  );
};

ContentAutorulesView.propTypes = {
  content: PropTypes.string,
  prerenderReady: PropTypes.bool,
};

ContentAutorulesView.defaultProps = {
  content: '',
  prerenderReady: false,
};

export default ContentAutorulesView;
