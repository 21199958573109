import PropTypes from 'prop-types';
import { flatten } from 'underscore';
import Head from 'Seo/components/head';
import { v as bvVar } from 'bv';
import { isRoot } from 'bv-helpers/locale';

const { location } = window;
const defaultLocale = bvVar('defaultLocale');

const convertPathName = (currentPathWithoutLocale, customPathWithoutLocale, isInRoot, locale) => {
  const isPrerender = navigator.userAgent.includes('Prerender');
  const seoCurrentPath = currentPathWithoutLocale.split('/market_group')[0];
  let pathname;

  if (isInRoot && locale === defaultLocale) {
    pathname = '';
  } else if (isPrerender) {
    pathname = `/${locale}${seoCurrentPath}`;
  } else if (customPathWithoutLocale || customPathWithoutLocale === '') {
    pathname = `/${locale}${customPathWithoutLocale}`;
  } else {
    pathname = `/${locale}${currentPathWithoutLocale}`;
  }
  return pathname;
};

const AlternateLanguagesView = ({
  currentPathWithoutLocale, customPathWithoutLocale, languages, externalLinks,
}) => {
  const isInRoot = isRoot(location.pathname);
  const pathName = (locale) => (
    convertPathName(currentPathWithoutLocale, customPathWithoutLocale, isInRoot, locale)
  );

  let links = flatten(Object.entries(languages)
    .map(([locale, alternateLangs]) => (alternateLangs || [])
      .map((alternateLang) => ({
        hreflang: alternateLang,
        href: `${location.protocol}//${location.host}${pathName(locale)}`,
      }))));

  links = [...links, ...Object.values(externalLinks).map((link) => ({
    hreflang: link.lang,
    href: link.external_url,
  }))];

  return (
    <Head>
      {links.map(({ hreflang, href }) => (
        <link
          rel="alternate"
          hrefLang={hreflang}
          href={href}
          key={hreflang}
        />
      ))}
    </Head>
  );
};

AlternateLanguagesView.propTypes = {
  currentPathWithoutLocale: PropTypes.string.isRequired,
  customPathWithoutLocale: PropTypes.string,
  languages: PropTypes.instanceOf(Object).isRequired,
  externalLinks: PropTypes.instanceOf(Object).isRequired,
};

AlternateLanguagesView.defaultProps = {
  customPathWithoutLocale: null,
};

export default AlternateLanguagesView;
