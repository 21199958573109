import { useFetch } from 'bv-hooks';
import PropTypes from 'prop-types';
import fetchMetaInfoTemplate from 'Seo/api/fetch_meta_info_template';
import MetaDataView from 'Seo/components/meta_data/meta_data_view';
import buildMetaInfo from './helpers/build_meta_info';

const MetaInfoContainer = ({ type, id, data }) => {
  const [templates, fetching] = useFetch(
    () => fetchMetaInfoTemplate({ type, id }),
    [id],
  );

  if (fetching) return null;

  // if we have metadata loaded, we assume all data for rendering is there
  // so we can safley tell prerender.io that we are done
  return <MetaDataView {...buildMetaInfo(templates, data)} prerenderReady />;
};

MetaInfoContainer.propTypes = {
  type: PropTypes.oneOf(['pre_event', 'in_play', 'meeting', 'game', 'casino_category', 'live_casino_category']).isRequired,
  id: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  metaDataProps: PropTypes.instanceOf(Object).isRequired,
};

export default MetaInfoContainer;
